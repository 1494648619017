import { apiClient } from 'api/ApiClient';

import type { ProfileSettings } from 'hooks/profile';

export type OnboardingFormData = {
  companyName: string;
  type: string;
  role: string;
} & ProfileSettings;

function sendInfoToZap({ firstName, lastName, companyName, email, role, type }: OnboardingInfo) {
  const zapUrl = import.meta.env.VITE_APP_ONBOARDING_ZAPIER;

  if (!zapUrl) {
    return Promise.resolve({});
  }

  return fetch(zapUrl, {
    method: 'POST',
    headers: {},
    body: JSON.stringify({
      firstName,
      lastName,
      company: companyName,
      email,
      role,
      type,
    }),
  });
}

export type OnboardingInfo = {
  email: string;
} & OnboardingFormData;

export type OnboardingInfoOptions = {
  updateCompany?: boolean;
  companyId?: string;
};

export function saveOnboardingInfo(
  { firstName, lastName, companyName, role, type, email }: OnboardingInfo,
  { updateCompany, companyId }: OnboardingInfoOptions = {},
) {
  return Promise.all([
    apiClient.updateProfile({
      first_name: firstName,
      last_name: lastName,
    }),
    ...(updateCompany && !!companyId
      ? [
          apiClient.updateCompanySettings(companyId, { name: companyName }),
          apiClient.updateWidgetSettings({
            client_name: companyName,
          }),
        ]
      : []),
    sendInfoToZap({ firstName, lastName, companyName, email, role, type }),
  ]);
}
