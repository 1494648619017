import { useCallback, useState } from 'react';

import { Button, Switch, Tooltip, Typography, styled } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { colors } from '@truvhq/ui';

import { useSandboxContext } from 'contexts/SandboxContext';

import { useFeatures } from 'hooks/features';
import { useAuth } from 'hooks/useAuth';

import SwitchOffSvg from './switch-off.svg';
import SwitchOnSvg from './switch-on.svg';

const StyledSwitch = styled(Switch)(() => ({
  // switch container size
  'width': 45,
  'height': 24,
  'padding': 0,

  // circle container and input
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'width': 12,
    'height': 12,
    'margin': 6,
    'left': 0,
    'transitionDuration': '200ms',

    // switch checked state
    '&.Mui-checked': {
      'transform': 'translateX(20px)',
      'color': colors.white,
      '& + .MuiSwitch-track': {
        'backgroundColor': colors.primary.main,
        'border': 0,
        // show "ON" and hide "OFF" while checked
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        },
      },
    },
  },

  // circle size
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },

  // track params
  '& .MuiSwitch-track': {
    'borderRadius': 30,
    'backgroundColor': colors.grey50,
    'opacity': 1,
    '&:before, &:after': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      content: '""',
      height: 7,
      transition: 'opacity 200ms ease',
    },
    '&:before': {
      backgroundImage: `url("${SwitchOnSvg}")`,
      width: 14,
      left: 8,
      opacity: 0,
    },
    '&:after': {
      backgroundImage: `url("${SwitchOffSvg}")`,
      width: 16,
      right: 7,
    },
  },
}));

export const LargeFontTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
}))(Tooltip);

type Props = {
  invert?: boolean;
};

export function SandboxSwitch({ invert }: Props) {
  const { user } = useAuth();
  const { data: features } = useFeatures();
  const { onSandboxSwitch, isSandboxOn, tooltipMessage } = useSandboxContext();

  const [isToolTipShown, setIsTooltipShown] = useState(false);

  const handleSandboxClick = useCallback(() => {
    setIsTooltipShown(false);

    const isSuccessful = onSandboxSwitch(!isSandboxOn);

    if (!isSuccessful) {
      setIsTooltipShown(true);
      setTimeout(() => {
        setIsTooltipShown(false);
      }, 8000);
    }
  }, [isSandboxOn, setIsTooltipShown, onSandboxSwitch]);

  const showIndividualVerifierSandboxSwitch = features?.flags?.sandbox_switch_individual_company;

  if (!user || (user.isCompanyIndividual && !showIndividualVerifierSandboxSwitch)) {
    return null;
  }

  const actionText = user.properties?.isProductionRequestSubmitted ? '' : 'Request production keys to get started.';

  const tooltipText = user.is_owner
    ? `You need production keys to enable production mode. ${actionText}`
    : 'Contact your administrator to enable production mode.';

  return (
    <LargeFontTooltip
      id="sandbox-tooltip"
      open={isToolTipShown}
      title={isToolTipShown && (tooltipMessage || tooltipText)}
    >
      <Button
        className="flex w-full items-center justify-between p-2"
        color="secondary"
        data-testid={`sandbox-switch-${isSandboxOn ? 'on' : 'off'}`}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        variant="text"
        onClick={handleSandboxClick}
      >
        <Typography sx={{ color: invert ? colors.white : colors.grey100 }} variant="body2">
          Sandbox
        </Typography>
        <StyledSwitch disableRipple checked={isSandboxOn} className="mx-4" />
      </Button>
    </LargeFontTooltip>
  );
}
