import type { ElementType, HTMLAttributeAnchorTarget, MouseEvent } from 'react';
import { forwardRef } from 'react';

import type { ButtonProps } from '@mui/material';
import { Button, IconButton } from '@mui/material';

import analytics from 'utils/analytics';

// this is to fix TS issue: https://github.com/mui-org/material-ui/issues/15827
type ExtendedButtonProps = {
  component?: ElementType;
  target?: HTMLAttributeAnchorTarget;
} & ButtonProps;

type ButtonWithAnalyticsProps = {
  eventName: string;
  eventProperties?: Record<string, unknown>;
} & ExtendedButtonProps;

export const AnalyticsButton = forwardRef<HTMLButtonElement, ButtonWithAnalyticsProps>(
  ({ eventName, eventProperties, onClick, ...buttonProps }, ref) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (onClick && typeof onClick === 'function') {
        onClick(e);
      }

      analytics.track(eventName, eventProperties);
    };

    return <Button ref={ref} {...buttonProps} onClick={handleClick} />;
  },
);

export const AnalyticsIconButton = forwardRef<HTMLButtonElement, ButtonWithAnalyticsProps>(
  ({ eventName, eventProperties, onClick, ...buttonProps }, ref) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (onClick && typeof onClick === 'function') {
        onClick(e);
      }

      analytics.track(eventName, eventProperties);
    };

    return <IconButton ref={ref} {...buttonProps} onClick={handleClick} />;
  },
);
