import type { ImgHTMLAttributes } from 'react';

import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

type LogoProps = ImgHTMLAttributes<HTMLImageElement> & {
  size?: 'xsmall' | 'small' | 'medium' | 'large';
};

const useStyles = makeStyles<Theme, LogoProps>({
  large: {
    height: 56,
  },
  medium: {
    height: 48,
  },
  small: {
    height: 24,
  },
  xsmall: {
    height: 14,
  },
});

const Logo = ({ className, size = 'small', ...props }: LogoProps) => {
  const classes = useStyles(props);

  return (
    <img alt="Logo" className={clsx(className, classes.logo, classes[size])} src="/static/logo-dark.svg" {...props} />
  );
};

export default Logo;
