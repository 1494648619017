import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.css';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from 'serviceWorker';

const container = document.getElementById('root');

if (!container) {
  throw new Error('Container not found');
}

Sentry.init({
  dsn: 'https://aeee9ba45d3d4977a286c8889efaaade@o1082479.ingest.sentry.io/4504016388227072',
  integrations: [new Sentry.BrowserTracing()],
  release: import.meta.env.VITE_APP_VERSION,
  environment: import.meta.env.VITE_APP_ENV,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: import.meta.env.VITE_APP_ENV === 'prod' ? 0.2 : 0,
  replaysOnErrorSampleRate: 1,
  enabled: !document.referrer.includes('localhost') && !document.referrer.includes('127.0.0.1'),
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

const root = createRoot(container);

root.render(<App />);

serviceWorker.unregister();
export { usePromo } from 'hooks/promo';
