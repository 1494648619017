import type { FC } from 'react';

import { Button, Dialog, DialogContent, DialogTitle, IconButton, ThemeProvider, Typography } from '@mui/material';
import { getTheme } from '@truvhq/ui';

import { Cross24Icon } from 'assets/icons';
import fannieBg from 'assets/images/promo/fannie-bg.svg';
import FannieCard from 'assets/images/promo/fannie-card.svg?react';
import FannieTitle from 'assets/images/promo/fannie-title.svg?react';
import FreddieCard from 'assets/images/promo/freddie-card.svg?react';
import Underline from 'assets/images/promo/underline.svg?react';

const promoTheme = getTheme({ primaryColor: '#7CB6FA' });

type Props = {
  href: string;
  onClick: () => void;
  onClose: () => void;
  open: boolean;
};

export const FannieModal: FC<Props> = ({ href, onClick, onClose, open }) => (
  <Dialog
    fullWidth
    PaperProps={{
      className: 'bg-transparent z-0 rounded-[32px] w-[560px]',
      sx: {
        backgroundImage: `url("${fannieBg}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    }}
    open={open}
    scroll="body"
    slotProps={{
      backdrop: {
        className: 'backdrop-blur-sm',
      },
    }}
  >
    <DialogTitle className="p-2.5 text-right">
      <IconButton aria-label="close" onClick={onClose}>
        <Cross24Icon className="text-white" />
      </IconButton>
    </DialogTitle>
    <DialogContent className="flex flex-col items-center pb-6 text-center">
      <FannieTitle className="shrink-0" />
      <Typography className="mt-6 text-lg leading-6 tracking-[-0.32px] text-white/60">
        Truv is the{' '}
        <span className="relative text-white">
          first
          <Underline className="absolute left-1/2 top-full -translate-x-1/2" />
        </span>{' '}
        consumer permissioned platform to support
        <br />
        both Fannie Mae’s Desktop Underwriter® validation service
        <br />
        and Freddie Mac’s Loan Product Advisor® (LPASM) asset
        <br />
        and income modeler (AIM).
      </Typography>
      <ThemeProvider theme={promoTheme}>
        <Button
          className="relative mt-8 h-14 shrink-0 bg-gradient-to-r from-[#7CB6FA] to-[#2D65E4] px-6 py-3 text-[16px] leading-6 text-white shadow-none transition-opacity hover:opacity-80 hover:shadow-none"
          href={href}
          target="_blank"
          variant="contained"
          onClick={onClick}
        >
          View announcement
        </Button>
      </ThemeProvider>
      <div className="mt-14 flex max-w-full gap-2">
        <FreddieCard />
        <FannieCard />
      </div>
    </DialogContent>
  </Dialog>
);
