import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { apiClient } from 'api/ApiClient';
import type { UpdateCompanyRequest } from 'api/types';

import { useAuth } from 'hooks/useAuth';

export function useGetCompany() {
  return useQuery({
    queryKey: ['getCompany'],
    queryFn: () => apiClient.getCompany(),
  });
}

export function useGetCompanySettings() {
  const { user } = useAuth();
  const id = user?.company?.id;

  return useQuery({
    queryKey: ['getCompanySettings', id],
    queryFn: () => (id ? apiClient.getCompanySettings(id) : Promise.reject()),
    enabled: !!id,
  });
}

export function useUpdateCompanySettings() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const client = useQueryClient();
  const id = user?.company?.id;

  const { mutate, isPending } = useMutation({
    mutationKey: ['updateCompanySettings'],
    mutationFn: (data: UpdateCompanyRequest) => (id ? apiClient.updateCompanySettings(id, data) : Promise.reject()),
    onMutate: () =>
      client.invalidateQueries({
        queryKey: ['getCompany'],
      }),
    onSuccess: (data) => {
      enqueueSnackbar('Your changes have been saved');

      client.setQueryData(['getCompanySettings', id], () => {
        return data;
      });
    },
  });

  return {
    update: mutate,
    isLoading: isPending,
  };
}
