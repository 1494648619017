import { useAuth } from 'hooks/useAuth';

const LoginView = () => {
  const { login } = useAuth();

  login();

  return <></>;
};

export default LoginView;
