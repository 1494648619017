import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useReducer } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { apiClient } from 'api/ApiClient';
import { baseApiClient } from 'api/BaseApiClient';

import { useImpersonation } from 'hooks/impersonation';

import { SplashScreen } from 'components/SplashScreen';

import type { Permission } from 'types/permissions';
import type { User } from 'types/user';

export type UnauthenticatedErrorCode = 'no_signups_with_public_email' | 'invite_expired';

type AuthState = {
  isInitialised: boolean;
  isAuthenticated: boolean;
  wasAuthenticated: boolean;
  user: User | null;
  permissions: Set<Permission>;
  companyPermissions: Set<Permission>;
  isGotError401?: boolean;
  authFailed?: boolean;
  authFailedError?: string;
  error?: UnauthenticatedErrorCode;
};

export type AuthContextValue = {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  signUp: () => Promise<void>;
  updateAuth: () => Promise<void>;
  checkAuth: () => boolean;
} & AuthState;

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    wasAuthenticated: boolean;
    user: User | null;
    permissions: Set<Permission>;
    companyPermissions: Set<Permission>;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type CheckAuthAction = {
  type: 'UPDATE_AUTH';
  payload: {
    isAuthenticated: boolean | undefined;
  };
};
type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Error401Action = {
  type: 'ERROR_401';
  payload: {
    isAuthenticated: boolean;
    error?: UnauthenticatedErrorCode;
  };
};

type ErrorAuthFailed = {
  type: 'AUTH_FAILED';
  payload: {
    authFailedError: string;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | Error401Action
  | ErrorAuthFailed
  | CheckAuthAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  permissions: new Set(),
  companyPermissions: new Set(),
  wasAuthenticated: false,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, wasAuthenticated, user, permissions, companyPermissions } = action.payload;

      return {
        ...state,
        isAuthenticated,
        wasAuthenticated,
        isInitialised: true,
        user,
        permissions,
        companyPermissions,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        ...initialAuthState,
      };
    }
    case 'ERROR_401': {
      return {
        ...state,
        isGotError401: true,
        error: action.payload.error,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case 'AUTH_FAILED': {
      return {
        ...state,
        authFailed: true,
        authFailedError: action.payload.authFailedError,
        isInitialised: true,
      };
    }
    case 'UPDATE_AUTH': {
      return {
        ...state,
        isAuthenticated: !!action.payload.isAuthenticated,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  updateAuth: () => Promise.resolve(),
  checkAuth: () => false,
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const queryClient = useQueryClient();
  const { impersonate } = useImpersonation();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);

  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    handleRedirectCallback,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const login = useCallback(async () => {
    try {
      await loginWithRedirect({
        appState: {
          backUrl: pathname + search,
        },
      });
    } catch (loginError) {
      console.error(loginError);
    }
  }, [loginWithRedirect, pathname, search]);

  const signUp = useCallback(async () => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
          screen_hint: 'signup',
        },
      });
    } catch (loginError) {
      console.error(loginError);
    }
  }, [loginWithRedirect]);

  const logoutCallback = useCallback(async () => {
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
      dispatch({ type: 'LOGOUT' });
    } catch (logoutError) {
      console.error(logoutError);
    }
  }, [logout]);

  const checkAuth = useCallback(() => {
    dispatch({ type: 'UPDATE_AUTH', payload: { isAuthenticated } });

    return isAuthenticated;
  }, [isAuthenticated]);

  const initializeState = () => {
    const initialise = async () => {
      try {
        await handleRedirectCallback().catch(() => {});

        if (!checkAuth()) {
          throw new Error('User is not authenticated');
        }

        // eslint-disable-next-line no-underscore-dangle
        baseApiClient.setCallbackAuthToken(async () => {
          const { id_token } = await getAccessTokenSilently({
            detailedResponse: true,
            cacheMode: 'on',
          });

          return id_token;
        });

        baseApiClient.setCallbackOn401((response?: { code?: UnauthenticatedErrorCode }) => {
          const error = response?.code;

          dispatch({ type: 'ERROR_401', payload: { isAuthenticated, error } });
        });

        try {
          if (!user) {
            throw new Error('Can`t get Auth0 user');
          }

          const userProfile = await queryClient.fetchQuery({
            queryKey: ['getProfile'],
            queryFn: () => apiClient.getProfile(),
          });

          if (
            params.get('action') === 'impersonate' &&
            params.has('target') &&
            params.has('id') &&
            userProfile.permissions.includes('can_use_impersonation')
          ) {
            const target = params.get('target') ?? '';
            const id = params.get('id') ?? '';

            impersonate({ target, id });

            return;
          }

          const [keys, properties] = await Promise.all([
            queryClient
              .fetchQuery({
                queryKey: ['getKeys'],
                queryFn: () => apiClient.getUserKeys(),
              })
              .catch(() => null),
            queryClient.fetchQuery({
              queryKey: ['getProperties'],
              queryFn: () => apiClient.getProperties(),
            }),
          ]);

          const hasProductionKey = keys?.prod.active_keys.length !== 0;
          const hasDevelopmentKey = keys?.dev.active_keys.length !== 0;

          const developmentPermissions = new Set<Permission>(['can_view_logs', 'can_view_keys', 'can_view_webhooks']);

          if (userProfile.permissions.some((permission: Permission) => developmentPermissions.has(permission))) {
            userProfile.permissions.push('can_view_development');
          }

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              wasAuthenticated: true,
              user: {
                id: user.sub ?? '',
                avatar: user.picture ?? '',
                email: user.email ?? '',
                name: user.name ?? '',
                email_verified: user.email_verified,
                company: userProfile.company,
                is_owner: userProfile.is_owner,
                is_approved: userProfile.is_approved,
                is_target_account: userProfile.is_target_account,
                isOnboardingFormComplete: Boolean(userProfile.first_name && userProfile.last_name),
                properties,
                profile: userProfile,
                hasProductionKey,
                hasDevelopmentKey,
                isCompanyIndividual: userProfile.company?.company_type === 'individual',
              },
              permissions: new Set(userProfile.permissions),
              companyPermissions: new Set(userProfile.company_permissions),
            },
          });
        } catch (err) {
          console.error(err);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null,
              wasAuthenticated: false,
              permissions: new Set(),
              companyPermissions: new Set(),
            },
          });
        }
      } catch (err) {
        console.log('caught auth0 error');
        console.error(err);

        if (params.get('error') === 'access_denied') {
          dispatch({
            type: 'AUTH_FAILED',
            payload: {
              authFailedError: params.get('error_description') ?? '',
            },
          });

          return;
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            wasAuthenticated: false,
            user: null,
            permissions: new Set(),
            companyPermissions: new Set(),
          },
        });
      }
    };

    void initialise();
  };

  const updateAuth = async () => {
    if (isAuthenticated) {
      try {
        await getAccessTokenSilently({
          cacheMode: 'off',
        });
      } catch (error) {
        console.error(error);
      }
    }

    initializeState();
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    initializeState();
  }, [isLoading]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout: logoutCallback,
        signUp,
        updateAuth,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
