import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import type { ImpersonateResponse } from 'api/types';

import { useFiltersContext } from 'contexts/FiltersContext';

import {
  getImpersonationProps,
  impersonationEmailKey,
  impersonationIdKey,
  setImpersonationParams,
} from 'utils/impersonation';

export const useImpersonation = () => {
  const { resetFilters } = useFiltersContext();
  const [email, setEmail] = useState(getImpersonationProps(impersonationEmailKey));
  const [id, setId] = useState(getImpersonationProps(impersonationIdKey));
  const history = useHistory();

  return {
    impersonate: ({ target, id }: ImpersonateResponse) => {
      resetFilters();
      setImpersonationParams(target, id);
      setEmail(email);
      setId(id);
      history.replace({ search: '' });
      history.go(0);
    },
    stopImpersonation: () => {
      resetFilters();
      setImpersonationParams('', '');
      setEmail('');
      setId('');
      history.replace({ search: '' });
      history.go(0);
    },
    email,
    id,
  };
};
