import type { FC, PropsWithChildren } from 'react';

import { Redirect } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

import { urls } from 'urls';

type Props = PropsWithChildren<{
  defaultRedirectUrl?: string;
}>;

export const GuestGuard: FC<Props> = ({ children, defaultRedirectUrl = urls.tasks.url() }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={defaultRedirectUrl} />;
  }

  return <>{children}</>;
};
