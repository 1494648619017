import type { FC, ReactNode } from 'react';
import { Fragment, Suspense } from 'react';

import { LoadingScreen } from '@truvhq/ui';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import type { Permission } from 'types/permissions';

export type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: FC<{ children: ReactNode }>;
  layout?: FC<{ children: ReactNode }>;
  component?: FC<RouteComponentProps>;
  routes?: Routes;
  permission?: Permission;
  companyPermission?: Permission;
}[];

type RenderRoutesProps = {
  routes?: Routes;
  defaultRedirectUrl: string;
};

export const RenderRoutes: FC<RenderRoutesProps> = ({ routes = [], defaultRedirectUrl }) => (
  <Switch>
    {routes.map((route) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component || Fragment;
      const key = route.path?.toString() || '';
      const guardProps: { permission?: Permission; companyPermission?: Permission; defaultRedirectUrl?: string } = {};

      if (route.guard) {
        guardProps.permission = route.permission;
        guardProps.companyPermission = route.companyPermission;
        guardProps.defaultRedirectUrl = defaultRedirectUrl;
      }

      return (
        <Route
          key={key}
          exact={route.exact}
          path={route.path}
          render={(props) => (
            <Guard {...guardProps}>
              <Layout>
                {route.routes ? (
                  <RenderRoutes defaultRedirectUrl={defaultRedirectUrl} routes={route.routes} />
                ) : (
                  <Suspense fallback={<LoadingScreen className="!mt-0" />}>
                    <Component {...props} />
                  </Suspense>
                )}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);
