import type { SVGProps } from 'react';

function TrashSimple(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="1em" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M7.83579 2.83579C8.21086 2.46071 8.71957 2.25 9.25 2.25H14.75C15.2804 2.25 15.7891 2.46071 16.1642 2.83579C16.5393 3.21086 16.75 3.71957 16.75 4.25V6.5C16.75 6.91421 16.4142 7.25 16 7.25C15.5858 7.25 15.25 6.91421 15.25 6.5V4.25C15.25 4.11739 15.1973 3.99021 15.1036 3.89645C15.0098 3.80268 14.8826 3.75 14.75 3.75H9.25C9.11739 3.75 8.99021 3.80268 8.89645 3.89645C8.80268 3.99021 8.75 4.11739 8.75 4.25V6.5C8.75 6.91421 8.41421 7.25 8 7.25C7.58579 7.25 7.25 6.91421 7.25 6.5V4.25C7.25 3.71957 7.46071 3.21086 7.83579 2.83579Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.7978 5.75225C19.2108 5.78401 19.5198 6.14456 19.4881 6.55756L18.5325 18.9801C18.4745 19.7337 18.1343 20.4376 17.5797 20.9512C17.0251 21.4647 16.2972 21.75 15.5414 21.75H8.45847C7.70265 21.75 6.97469 21.4647 6.42013 20.9512C5.86558 20.4376 5.5253 19.7337 5.46734 18.9801L6.21513 18.9226L5.46734 18.9801L4.51177 6.55756C4.48 6.14456 4.78904 5.78401 5.20204 5.75225C5.61503 5.72048 5.97558 6.02952 6.00735 6.44251L6.96292 18.8651L6.21531 18.9226L6.96292 18.8651C6.9919 19.2419 7.16204 19.5938 7.43932 19.8506C7.71659 20.1074 8.08058 20.25 8.45849 20.25H15.5413C15.9193 20.25 16.2832 20.1074 16.5605 19.8506C16.8378 19.5938 17.0079 19.2419 17.0369 18.8651L17.9925 6.44251C18.0243 6.02952 18.3848 5.72048 18.7978 5.75225Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.25 6.5C3.25 6.08579 3.58579 5.75 4 5.75H20C20.4142 5.75 20.75 6.08579 20.75 6.5C20.75 6.91421 20.4142 7.25 20 7.25H4C3.58579 7.25 3.25 6.91421 3.25 6.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default TrashSimple;
