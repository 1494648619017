import type { Analytics, EventProperties, ID, UserTraits } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { History } from 'history';

const LOCAL_STORE_FULLSTORY_FLAG_KEY = 'fullstory_enabled';
const LOCAL_STORE_FULLSTORY_TIMESTAMP_KEY = 'fullstory_timestamp';
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const FULLSTORY_SAMPLE_RATE = 0.1; // record 10% of sessions

export const getIsFullStoryEnabled = (fullStorySampleRate: number) => {
  try {
    const timestamp = Date.now();
    const storeValue = window.localStorage.getItem(LOCAL_STORE_FULLSTORY_FLAG_KEY);
    const storeTime = window.localStorage.getItem(LOCAL_STORE_FULLSTORY_TIMESTAMP_KEY);

    // if was enabled recently than keep enabled for 1 day
    if (storeValue && storeTime && Number(storeTime) + ONE_DAY_IN_MS > timestamp) {
      return storeValue === 'true';
    }

    const isFullStoryEnabled = Math.random() < fullStorySampleRate;

    window.localStorage.setItem(LOCAL_STORE_FULLSTORY_FLAG_KEY, String(isFullStoryEnabled));
    window.localStorage.setItem(LOCAL_STORE_FULLSTORY_TIMESTAMP_KEY, String(timestamp));

    return isFullStoryEnabled;
  } catch (e) {
    return false;
  }
};

type InitParams = {
  history: History;
  enablesFullStory?: boolean;
  isIndividual?: boolean;
  fullStorySampleRate?: number;
};

class AnalyticsClient {
  private analytics: Analytics | undefined;

  private pageView = () => {
    void this.analytics?.page();
  };

  public init = ({ history, enablesFullStory = true, fullStorySampleRate = FULLSTORY_SAMPLE_RATE }: InitParams) => {
    return AnalyticsBrowser.load(
      { writeKey: import.meta.env.VITE_APP_SEGMENT_KEY ?? '' },
      {
        integrations: {
          FullStory: enablesFullStory && getIsFullStoryEnabled(fullStorySampleRate),
        },
      },
    )
      .then(([analytics]) => {
        this.analytics = analytics;

        if (analytics.integrations.FullStory) {
          this.track('FullStory enabled');
        }

        // listen all url changes
        history.listen(this.pageView);
        // track current page
        this.pageView();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  public identify = (userId?: ID, traits?: UserTraits) => {
    void this.analytics?.identify(userId, traits);
  };

  public track = (type: string, props?: EventProperties) => {
    if (!import.meta.env.PROD) {
      // eslint-disable-next-line no-console
      console.log('analytics event:', type, props);
    }

    void this.analytics?.track(type, props);
  };
}

const client = new AnalyticsClient();

export default client;
