import { useCallback, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { useIdleTimer } from 'react-idle-timer';
import { useModal } from 'react-modal-hook';

import { formatDuration } from 'utils/formatDate';

import { useAuth } from 'hooks/useAuth';

import { ClockIcon } from 'assets/icons';

// TODO: change after testing
const timeout = 30 * 60_000; // 30 minutes
const promptBeforeIdle = 2 * 60_000; // 2 minutes

export const useSessionExpiredModal = () => {
  const { logout, updateAuth, login, isAuthenticated } = useAuth();
  const [remainingTime, setRemainingTime] = useState(formatDuration(promptBeforeIdle));
  const [isExpired, setIsExpired] = useState(false);

  const [showModal, hideModal] = useModal(
    ({ in: open = false }: TransitionProps) => {
      return (
        <Dialog
          fullWidth
          PaperProps={{
            className: 'max-w-[400px]',
          }}
          open={open}
          slotProps={{
            backdrop: {
              className: 'backdrop-blur-sm',
            },
          }}
        >
          <DialogContent className="px-5 pb-12 pt-9 text-center">
            <ClockIcon className="text-primary-main" />
            <Typography className="mb-2 mt-4 text-center" variant="h1">
              {isExpired ? 'Session expired' : 'Session about to expire'}
            </Typography>
            <Typography className="text-center" variant="body1">
              {isExpired ? 'Your session has timed out. Log in again.' : `You will be logged out ${remainingTime}.`}
            </Typography>
          </DialogContent>

          <DialogActions className="whitespace-nowrap px-4 pb-5 pt-0">
            {!isExpired && (
              <Button
                fullWidth
                className="py-3 text-[16px] leading-6"
                color="secondary"
                variant="contained"
                onClick={() => {
                  hideModal();
                  void logout();
                }}
              >
                Log out
              </Button>
            )}
            <Button
              fullWidth
              className="py-3 text-[16px] leading-6"
              variant="contained"
              onClick={() => {
                hideModal();

                if (isExpired) {
                  void login();

                  return;
                }

                activate();
                void updateAuth();
              }}
            >
              {isExpired ? 'Log in' : 'Stay connected'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    [remainingTime, isExpired],
  );

  const handleSessionExpired = useCallback(() => {
    setIsExpired(true);
    showModal();
  }, [showModal]);

  const { getRemainingTime, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    startOnMount: isAuthenticated,
    onActive() {
      void updateAuth();
    },
    onPrompt() {
      showModal();
    },
    onIdle() {
      setIsExpired(true);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(formatDuration(getRemainingTime()));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  return { handleSessionExpired };
};
