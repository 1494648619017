import type { FC } from 'react';

import { Helmet } from 'react-helmet';

const GoogleMaps: FC = () => {
  return (
    <Helmet>
      <script
        async
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDVEQ5PaG1lWuq3tr5xQ4v3kqZWFIFhQow&libraries=places"
      />
    </Helmet>
  );
};

export default GoogleMaps;
