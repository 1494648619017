import type { ProfileResponse } from 'api/types';

import { useSandboxContext } from 'contexts/SandboxContext';

import { useGetProfile } from 'hooks/profile';

export const getEnv = (isSandboxOn: boolean, profile?: ProfileResponse): 'dev' | 'prod' | 'sandbox' => {
  if (!isSandboxOn && !profile?.env_types?.includes('prod')) {
    return 'dev';
  }

  if (!isSandboxOn) {
    return 'prod';
  }

  return 'sandbox';
};

export const useCurrentEnv = (): 'dev' | 'prod' | 'sandbox' => {
  const { isSandboxOn } = useSandboxContext();
  const { data: profile } = useGetProfile();

  return getEnv(isSandboxOn, profile);
};
