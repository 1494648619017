import { setTags, setUser } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

export type ProfileSettings = {
  firstName?: string;
  lastName: string;
};

export function useGetProfile() {
  const result = useQuery({ queryKey: ['getProfile'], queryFn: () => apiClient.getProfile() });
  const { isSuccess, data } = result;

  if (isSuccess && data) {
    const { email, company: { id: company } = {}, is_target_account } = data;

    setTags({ email, company, is_target_account });
    setUser({ email });
  }

  return result;
}

export function useGetProperties() {
  return useQuery({ queryKey: ['getProperties'], queryFn: () => apiClient.getProperties() });
}

export function saveProfile({ firstName, lastName }: ProfileSettings) {
  return apiClient.updateProfile({
    first_name: firstName,
    last_name: lastName,
  });
}

export const useProfileSuggest = () => {
  const { data } = useQuery({ queryKey: ['getProfileSuggest'], queryFn: () => apiClient.getProfileSuggest() });

  return {
    suggest: data,
  };
};
