import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { apiClient } from 'api/ApiClient';
import type { BrandingResponse, ExcludedFields, WidgetSettings } from 'api/types';

import type { ProfileSettings } from 'hooks/profile';

import { useAuth } from './useAuth';

export type DemoRequestSource = 'demo request' | 'production key request';

export type OnboardingInfo = {
  companyName: string;
  phone: string;
  email: string;
  source: DemoRequestSource;
  product: string;
  description: string;
} & ProfileSettings;

export function sendDemoRequest({
  firstName,
  lastName,
  companyName,
  phone,
  email,
  source,
  product,
  description,
}: OnboardingInfo) {
  const zapUrl = import.meta.env.VITE_APP_DEMO_REQUEST_ZAPIER;

  if (!zapUrl) {
    // eslint-disable-next-line no-console
    console.error('no zapier url provided');

    return Promise.resolve({});
  }

  return fetch(zapUrl, {
    method: 'POST',
    headers: {},
    body: JSON.stringify({
      firstName,
      lastName,
      phoneNumber: phone,
      company: companyName,
      email,
      product,
      description,
      source,
    }),
  });
}

export function useGetBrandingSettings() {
  return useQuery({ queryKey: ['getBrandingSettings'], queryFn: () => apiClient.getBrandingSettings(), retry: false });
}

export function useUpdateBrandingSettings() {
  const queryCache = useQueryClient();

  return useMutation({
    mutationKey: ['brandingSettings'],
    mutationFn: (newSettings: Partial<BrandingResponse>) => {
      return apiClient.updateBrandingSettings(newSettings);
    },
    onSuccess: (data) => {
      queryCache.setQueryData(['getBrandingSettings'], () => {
        return data;
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
}

export function useGetWidgetSettings() {
  return useQuery({ queryKey: ['getWidgetSettings'], queryFn: () => apiClient.getWidgetSettings(), retry: false });
}

export function saveRequestSentState() {
  return apiClient.updateProperties({ isCustomizationRequestSent: true });
}

export type BrandingFormData = {
  companyName: string;
  helpButton: boolean;
  primaryColor: string;
  privacyPolicy: boolean;
  whiteLabel: boolean;
  logo?: File | string;
  customDomain?: string;
};

export const saveSettings = (newSettings: Partial<WidgetSettings>) => {
  return apiClient.updateWidgetSettings(newSettings);
};

export const useUpdateLogo = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (newLogo?: File | null): Promise<WidgetSettings> => {
      if (newLogo) {
        return apiClient.uploadLogo(newLogo);
      }

      return apiClient.deleteLogo();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['getWidgetSettings'], () => data);
    },
    onError: (error) => {
      enqueueSnackbar('Logo update failed');
      console.log(error);
    },
  });

  return {
    isLogoUpdating: isPending,
    updateLogo: (newLogo?: File | null) => mutateAsync(newLogo),
  };
};

type UpdateBrandingOptions = {
  shouldUpdateLogo?: boolean;
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  const { permissions } = useAuth();
  const canChangeCustomDomain = permissions.has('can_edit_custom_domain');
  const { data: widgetSettings } = useGetWidgetSettings();
  const { isLogoUpdating, updateLogo } = useUpdateLogo();

  const widgetSettingsMutation = useMutation({
    mutationKey: ['widgetSettings'],
    mutationFn: (newSettings: Partial<WidgetSettings>) => saveSettings(newSettings),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWidgetSettings'] });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    isLogoUpdating,
    isSettingsUpdating: widgetSettingsMutation.isPending,
    updateFields: (fields: ExcludedFields) => {
      return widgetSettingsMutation.mutateAsync({ cf_exclude_fields: fields });
    },
    updateEmulator: async (newSettings: BrandingFormData, options?: UpdateBrandingOptions) => {
      const brandingSettings: Partial<WidgetSettings> = {
        client_name: newSettings.companyName,
        color_primary: newSettings.primaryColor,
        ...(canChangeCustomDomain && {
          custom_domain: newSettings.customDomain || null,
        }),
      };

      if (widgetSettings?.logo && !newSettings.logo && options?.shouldUpdateLogo) {
        await updateLogo(undefined);
      }

      if (newSettings.logo && newSettings.logo instanceof File) {
        await updateLogo(newSettings.logo);
      }

      return widgetSettingsMutation.mutateAsync(brandingSettings);
    },
    updateCustom: async (newSettings: Partial<WidgetSettings>) => {
      return widgetSettingsMutation.mutateAsync(newSettings);
    },
  };
};
