import type { FC, ReactNode } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import analytics from '../utils/analytics';

import { CloseIcon } from 'assets/icons';

import { AnalyticsButton } from './AnalyticsButton';

export type Action = {
  name: string;
  onClick: () => void;
  event: string;
  color: 'primary' | 'secondary';
};

type ConfirmationDialogProps = {
  onClose: () => void;
  icon: ReactNode;
  isDialogOpen: boolean;
  actions: Action[];
  title: string;
  subtitle: ReactNode;
  closeEvent: string;
  dataTestId?: string;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isDialogOpen,
  onClose,
  icon,
  actions,
  title,
  subtitle,
  closeEvent,
  dataTestId,
}) => {
  const handleClose = () => {
    analytics.track(closeEvent);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      aria-labelledby="responsive-dialog-title"
      data-testid={dataTestId}
      maxWidth="xs"
      open={isDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle className="text-black text-md flex justify-end py-3 pl-6 pr-3" id="responsive-dialog-title">
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="mb-3 flex flex-col">
        <Box className="mb-4 flex w-full justify-center">{icon}</Box>
        <Box>
          <Typography align="center" className="leading-8" variant="h1">
            {title}
          </Typography>
          <Typography align="center" className="mt-2" variant="body1">
            {subtitle}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions disableSpacing className="flex flex-col px-4 py-2">
        {actions.map((action, index) => {
          return (
            <div key={index} className="w-full pb-3">
              <AnalyticsButton
                fullWidth
                color={action.color}
                eventName={action.event}
                size="large"
                variant="contained"
                onClick={action.onClick}
              >
                {action.name}
              </AnalyticsButton>
            </div>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};
