import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { apiClient } from 'api/ApiClient';
import type { CreateMemberResult, Member, RoleId, UpdateMemberResult } from 'api/types';

import { useAuth } from 'hooks/useAuth';

import { Reject, TrashSimple } from 'assets/icons';

import { useConfirm } from './confirm';

export const useMembers = (enabled = true) => {
  return useQuery({ queryKey: ['getMembers'], queryFn: () => apiClient.getMembers(), enabled });
};

export function useGetRoles() {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['getRoles'],
    queryFn: () => apiClient.getRoles(),
    select: (data) => (user?.isCompanyIndividual ? data.filter((role) => role.id !== 'developer') : data),
  });
}

export type CreateMemberFormData = {
  email: string;
  firstName: string;
  lastName: string;
  role: RoleId;
  access_to_templates: string[];
};

export const useCreateMember = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending, error } = useMutation<CreateMemberResult, unknown, CreateMemberFormData>({
    mutationFn: ({ email, firstName, lastName, role, access_to_templates }) =>
      apiClient.createMember({
        email,
        first_name: firstName,
        last_name: lastName,
        role,
        access_to_templates,
      }),
    async onSuccess() {
      await queryClient.refetchQueries({
        queryKey: ['getMembers'],
      });
    },
  });

  return {
    createMember: mutateAsync,
    isLoading: isPending,
    error,
  };
};

export type UpdateMemberVars = {
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  access_to_templates: string[];
};

export const useUpdateMember = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation<UpdateMemberResult, unknown, UpdateMemberVars>({
    mutationFn: async ({ userId, firstName, lastName, role, access_to_templates }) =>
      await apiClient.updateMember(userId, {
        first_name: firstName,
        last_name: lastName,
        role,
        access_to_templates,
      }),
    async onSuccess() {
      await queryClient.refetchQueries({
        queryKey: ['getMembers'],
      });
    },
  });

  return {
    updateMember: mutateAsync,
    isLoading: isPending,
  };
};

export const useApproveMember = (member?: Member) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (approved: boolean) => {
      await apiClient.approveMember(member?.user_id ?? '', approved);

      return true;
    },
    async onSuccess(result, approved) {
      if (!result) {
        return;
      }

      await queryClient.refetchQueries({
        queryKey: ['getMembers'],
      });
      enqueueSnackbar(approved ? 'Member approved' : 'Member declined');
    },
  });

  return {
    approveMember: mutateAsync,
    isLoading: isPending,
  };
};

export const useDeclineMemberWithConfirm = (member: Member) => {
  const { approveMember, isLoading } = useApproveMember(member);

  const { showConfirm, hideConfirm } = useConfirm({
    title: `Are you sure you want to revoke access for ${member.first_name} ${member.last_name}?`,
    subtitle: `Revoking access prevents user with email ${member.email} from accessing Truv dashboard.`,
    icon: (
      <div className="h-12 w-12 rounded-xl bg-brightRed p-3 text-red-main">
        <Reject />
      </div>
    ),
    closeEvent: 'Close Decline Confirm',
    actions: [
      {
        name: 'Revoke access',
        event: 'Confirm Decline',
        color: 'primary',
        async onClick() {
          hideConfirm();
          await approveMember(false);
        },
      },
    ],
  });

  return {
    declineMember: showConfirm,
    isLoading,
  };
};

export const useDeleteMember = (member: Member) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { isPending, mutate } = useMutation({
    mutationFn: () => apiClient.deleteMember(member.user_id),
    async onSuccess() {
      await queryClient.refetchQueries({
        queryKey: ['getMembers'],
      });
      enqueueSnackbar('Member deleted');
    },
  });

  return {
    deleteMember: mutate,
    isLoading: isPending,
  };
};

export const useDeleteMemberWithConfirm = (member: Member) => {
  const { deleteMember, isLoading } = useDeleteMember(member);
  const { showConfirm, hideConfirm } = useConfirm({
    title: `Are you sure you want to delete member ${member.first_name} ${member.last_name}?`,
    subtitle: `User with email ${member.email} will be removed.`,
    actions: [
      {
        name: 'Delete member',
        event: 'Confirm Delete Member',
        color: 'primary',
        onClick: () => {
          deleteMember();
          hideConfirm();
        },
      },
    ],
    icon: (
      <div className="h-12 w-12 rounded-xl bg-brightRed p-3 text-red-main">
        <TrashSimple className="h-6 w-6" />
      </div>
    ),
    closeEvent: 'Close Delete Member Confirm',
  });

  return {
    deleteMember: showConfirm,
    isLoading,
  };
};

export type ResendInviteVars = {
  userId: string;
};

export const useResendInvite = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation<Member, unknown, ResendInviteVars>({
    mutationFn: ({ userId }) => apiClient.resendInvite(userId),
    async onSuccess(data) {
      await queryClient.refetchQueries({
        queryKey: ['getMembers'],
      });
      enqueueSnackbar(`New invite was sent to ${data?.email}`);
    },
  });

  return {
    resendInvite: mutateAsync,
    isResending: isPending,
  };
};
