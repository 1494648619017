import type { TextFieldProps } from '@mui/material';
import { Select as BaseSelect } from '@truvhq/ui';

import { Dropdown as SvgDropdown } from 'assets/icons';

type OuterProps = TextFieldProps;

export const Select = ({ SelectProps, label, value, ...props }: OuterProps) => {
  return (
    <BaseSelect
      label={label}
      value={value}
      {...props}
      SelectProps={{
        IconComponent: SvgDropdown,
        ...SelectProps,
      }}
      sx={{
        '.MuiSelect-iconOutlined': {
          top: 'calc(50% - 8px)',
          right: 16,
          opacity: props.disabled ? 0.6 : 1,
        },
      }}
    />
  );
};
