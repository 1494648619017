export const decodeQueryParams = <T = Record<string, string | string[]>>(query: string) => {
  const entries = new URLSearchParams(query).entries();

  const result: Record<string, unknown> = {};

  for (const [key, value] of entries) {
    if (result[key]) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key]];
      }

      (result[key] as string[]).push(value);
    } else {
      result[key] = value;
    }
  }

  return result as T;
};

export type Primitive = string | number | boolean | null | undefined;

export const encodeQueryParams = (obj: Record<string, Primitive | Primitive[]>) => {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key, String(item));
      });
    } else {
      params.append(key, String(value));
    }
  });

  return params.toString();
};
