import Color from 'color';
import floor from 'lodash-es/floor';

export function getNumberText(num: number) {
  if (num === 1) {
    return 'One';
  }

  if (num === 2) {
    return 'Two';
  }

  return num.toString(10);
}

export function unflatten(data: Record<string, unknown>) {
  const result: { [key: string]: any } = {};

  for (const i in data) {
    const keys = i.split('.');

    keys.reduce((r, e, j) => {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : []);
    }, result);
  }

  return result;
}

const hasOwnProperty = Object.prototype.hasOwnProperty;

export function flatten(ob: Record<string, any>) {
  const toReturn: Record<string, unknown> = {};

  for (const i in ob) {
    if (!hasOwnProperty.call(ob, i)) {
      continue;
    }

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      const flatObject = flatten(ob[i]);

      for (const x in flatObject) {
        if (!hasOwnProperty.call(flatObject, x)) {
          continue;
        }

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }

  return toReturn;
}

export const notNull = <T>(x: T | null): x is T => x !== null;

export function isContrastColor(color?: string, reference = '#FFF', difference = 3) {
  return Color(color).contrast(Color(reference)) >= difference;
}

export function isFalsy(value: any) {
  return !value;
}

export function toPercent(value: number | null): string {
  if (typeof value === 'number') {
    return `${floor(value * 100, 1)}%`;
  }

  return '–';
}
