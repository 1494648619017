export const impersonationEmailKey = 'impersonationEmail';
export const impersonationIdKey = 'impersonationId';

export const setImpersonationParams = (newEmail: string, newId: string) => {
  try {
    localStorage.setItem(impersonationEmailKey, newEmail);
    localStorage.setItem(impersonationIdKey, newId);
  } catch (e) {
    console.error('Failed to write impersonation params to local storage');
    console.error(e);
  }
};

export const getImpersonationProps = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(`Failed to read ${key} from local storage`);
    console.error(e);

    return null;
  }
};
