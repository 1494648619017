import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@mui/material';
import { NavButton, colors } from '@truvhq/ui';
import { clsx } from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { isItemHidden } from './utils/isItemHidden';
import { isRoutePart } from './utils/isRoutePart';

import type { Item, RenderItemsProps, State } from './types';
import { ChevronDown } from 'assets/icons';

type Props = {
  items?: Item[];
  onItemClick: (item?: string) => void;
} & RenderItemsProps;

export const ACCORDION_TRANSITION_DURATION = 100;

const NavItem = ({
  item,
  pathname,
  permissions,
  isSubItem,
  onClick,
}: {
  item: Item;
  isSubItem?: boolean;
  onClick?: (item?: string) => void;
} & Pick<RenderItemsProps, 'pathname' | 'permissions'>) => {
  if (isItemHidden(item, permissions)) {
    return null;
  }

  return (
    <div className="group relative">
      <NavButton
        active={isRoutePart(pathname, item.href)}
        className={clsx(isSubItem && 'pl-2', item.items && 'mt-3')}
        component={RouterLink}
        title={item.title}
        to={{ pathname: !item.href ? '' : item.href, state: { internal: true } }}
        onClick={() => {
          onClick?.(item.href);
          item.customAction?.();
        }}
      />

      {item.info}
    </div>
  );
};

export const NavItems = ({ items = [], pathname, permissions, submenuState, setSubmenuState, onItemClick }: Props) => {
  return (
    <List disablePadding className="flex flex-col gap-0.5">
      {items.map((item) => {
        const shownSubmenuItems = item.submenu?.filter((i) => !isItemHidden(i, permissions)) ?? [];

        if (!item.submenu || shownSubmenuItems.length <= 1) {
          return <NavItem key={item.title} item={item} pathname={pathname} permissions={permissions} />;
        }

        const isExpanded = (item.href && submenuState[item.href]) || false;

        return (
          <Accordion
            key={item.title}
            disableGutters
            className="bg-transparent text-white"
            expanded={isExpanded}
            slotProps={{ transition: { timeout: ACCORDION_TRANSITION_DURATION } }}
            sx={{
              '&.MuiPaper-root.MuiAccordion-root': {
                ml: 2,
                mr: 2,
              },
              '& .MuiAccordionSummary-root': {
                '&:hover': {
                  bgcolor: colors.grey80,
                },
                '&.Mui-expanded': {
                  minHeight: 0,
                },
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
              '& .MuiAccordionDetails-root': {
                pt: 0,
              },
              '& .MuiListItem-root .MuiButton-root': {
                ml: 0,
                mr: 0,
              },
              '& .MuiCollapse-root': {
                pt: 0.25,
              },
            }}
            onChange={() => {
              if (item.href && item.href in submenuState) {
                setSubmenuState({
                  ...submenuState,
                  [item.href]: !submenuState[item.href],
                  currentItem: item.href,
                } as State);
              }
            }}
          >
            <AccordionSummary
              disableRipple
              aria-controls={`${item.href || item.title}-content`}
              className="m-0 min-h-0 rounded-lg px-2 py-1.5"
              expandIcon={
                <ChevronDown
                  className={clsx('text-grey70', isExpanded ? '-rotate-180' : '-rotate-90')}
                  height={20}
                  width={20}
                />
              }
              id={`${item.href || item.title}-header`}
            >
              <Typography variant="body2">{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col gap-0.5">
              {item.submenu.map((subItem) => {
                return (
                  <NavItem
                    key={subItem.title}
                    isSubItem
                    item={subItem}
                    pathname={pathname}
                    permissions={permissions}
                    onClick={onItemClick}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </List>
  );
};
