import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { UTCDateMini } from '@date-fns/utc';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import omitBy from 'lodash-es/omitBy';
import throttle from 'lodash-es/throttle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import type { EnvType, OrderFilterStatus, TaskStatus, TemplatesProductCombinations } from 'api/types';

import { encodeQueryParams } from 'utils/queryParams';

import { useQueryParams } from 'hooks/useQueryParams';

import { useSandboxContext } from './SandboxContext';
import { urls } from 'urls';

export const DEFAULT_PERIOD_DAYS = 30;
export const DEFAULT_PAGE_SIZE = 20;
export const STORAGE_FILTERS_KEY = 'common_filters';

export type Filters = {
  env: EnvType[];
  created_at__gte: string;
  created_at__lte: string;
  orderStatus?: OrderFilterStatus;
  taskStatus?: TaskStatus;
  responseStatus?: string;
  product_type?: string;
  provider?: string;
  products?: TemplatesProductCombinations;

  query?: string;

  page?: number;
  page_size?: number;
  ordering?: string;
};

type FiltersContextType = {
  filters: Filters;
  filtersQuery: string;
  updateFilters: (filters: Partial<Filters>) => void;
  resetFilters: () => void;
};

const defaultFilters: Filters = {
  env: ['sandbox'],
  created_at__gte: subDays(startOfDay(new UTCDateMini()), DEFAULT_PERIOD_DAYS).toISOString(),
  created_at__lte: endOfDay(new UTCDateMini()).toISOString(),
};

let storageFilters: Filters = {
  ...defaultFilters,
};

const possibleEnvs: EnvType[] = ['prod', 'dev', 'sandbox'];

try {
  const storageValue = sessionStorage.getItem(STORAGE_FILTERS_KEY);

  const filters: Filters = JSON.parse(storageValue as string);

  if (filters.env && Array.isArray(filters.env) && filters.env.every((item) => possibleEnvs.includes(item))) {
    storageFilters.env = filters.env;
  }

  if (filters.created_at__gte && Date.parse(filters.created_at__gte)) {
    storageFilters.created_at__gte = new Date(filters.created_at__gte).toISOString();
  }

  if (filters.created_at__lte && Date.parse(filters.created_at__lte)) {
    storageFilters.created_at__lte = new Date(filters.created_at__lte).toISOString();
  }

  // eslint-disable-next-line no-empty
} catch (e) {}

const defaultContextValue: FiltersContextType = {
  filters: storageFilters,
  filtersQuery: encodeQueryParams(storageFilters),
  updateFilters: () => {},
  resetFilters: () => {},
};

export const FiltersContext = createContext(defaultContextValue);

const mergeFilters = (filters: Filters, newFilters: Partial<Filters>): Filters => ({
  ...filters,
  ...Object.entries(newFilters).reduce<Partial<Filters>>((prev, [key, value]) => {
    if (value !== undefined) {
      // @ts-expect-error
      prev[key] = value;
    }

    return prev;
  }, {}),
});

const FiltersContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isSandboxOn } = useSandboxContext();
  const history = useHistory();

  const queryParams = useQueryParams<{
    env?: string | string[];
    created_at__gte?: string;
    created_at__lte?: string;
    query?: string;
  }>();

  const { env: queryParamsEnv, created_at__gte, created_at__lte, query } = queryParams;
  const env = !!queryParamsEnv && ((Array.isArray(queryParamsEnv) ? queryParamsEnv : [queryParamsEnv]) as EnvType[]);

  const [filters, setFilters] = useState<Filters>({
    env: env && env.every((item) => possibleEnvs.includes(item)) ? env : storageFilters.env,
    created_at__gte: created_at__gte || storageFilters.created_at__gte,
    created_at__lte: created_at__lte || storageFilters.created_at__lte,
    query: query ?? '',
  });

  const filtersQuery = useMemo(() => {
    return encodeQueryParams(filters);
  }, [filters]);

  const updateFilters = throttle((partialFilters: Partial<Filters>) => {
    const newFilters = mergeFilters(filters, partialFilters);

    try {
      const { query: _, page, page_size, ...filtersToCache } = newFilters;

      sessionStorage.setItem(STORAGE_FILTERS_KEY, JSON.stringify(filtersToCache));
      // eslint-disable-next-line no-empty
    } catch (e) {}

    setFilters(newFilters);

    const otherQueryParams = omitBy(queryParams, (_, key) => Object.keys(newFilters).includes(key));

    const search = encodeQueryParams({ ...newFilters, ...otherQueryParams });

    history.replace({ search });
  }, 500);

  const resetFilters = () => {
    storageFilters = { ...defaultFilters };

    const otherQueryParams = omitBy(queryParams, (_, key) => Object.keys(storageFilters).includes(key));

    history.replace({ search: encodeQueryParams(otherQueryParams) });
    sessionStorage.removeItem(STORAGE_FILTERS_KEY);
  };

  const matchNoFiltersPage = useRouteMatch({
    path: [urls.home.url(), urls.verification.url(), urls.logs.url(), urls.templates.url(), urls.emulator.url()],
  });

  useEffect(() => {
    if (isSandboxOn != null) {
      const env: EnvType[] = isSandboxOn ? ['sandbox'] : ['dev', 'prod'];

      if (matchNoFiltersPage) {
        return;
      }

      if (env.some((item) => !filters.env.includes(item))) {
        updateFilters({ env });
      }
    }
  }, [isSandboxOn, updateFilters, matchNoFiltersPage]);

  return (
    <FiltersContext.Provider value={{ filters, filtersQuery, updateFilters, resetFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (context === undefined) {
    throw new Error('useFiltersContext must be used within a FiltersContextProvider');
  }

  return context;
};

const useFilters = ({ page = 1, page_size = DEFAULT_PAGE_SIZE } = { page: 1, page_size: DEFAULT_PAGE_SIZE }) => {
  const { filters, updateFilters } = useFiltersContext();
  const { query } = useQueryParams<{
    query?: string;
  }>();

  const [filtersToDisplay, setFiltersToDisplay] = useState<Filters>({
    ...filters,
    query,
    page,
    page_size,
  });

  useDeepCompareEffect(() => {
    setFiltersToDisplay({ ...filters, page: filtersToDisplay.page, page_size: filtersToDisplay.page_size });
  }, [filters]);

  useEffect(() => {
    updateFilters({ query: query ?? '' });
    setFiltersToDisplay({ ...filtersToDisplay, query: query ?? '' });
  }, [query]);

  return {
    defaultFilters,
    filters: filtersToDisplay,
    updateFilters: (filters: Partial<Filters>) => {
      const { page, page_size, ...rest } = filters;

      updateFilters(rest);
      setFiltersToDisplay(mergeFilters(filtersToDisplay, filters));
    },
  };
};

export { FiltersContextProvider, useFiltersContext, useFilters };
