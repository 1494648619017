import type { ReactNode } from 'react';
import { useState } from 'react';

import { useModal } from 'react-modal-hook';

import type { Action } from 'components/ConfirmationDialog';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

type StringBuilder<T> = (context: T | null) => string;
type ActionsBuilder<T> = (context: T | null) => Action[];

type UseConfirmParams<T> = {
  title: string | StringBuilder<T>;
  subtitle: string | StringBuilder<T>;
  icon?: ReactNode;
  closeEvent: string;
  actions: Action[] | ActionsBuilder<T>;
};

export const useConfirm = <T extends object | number>({
  title,
  subtitle,
  actions,
  icon,
  closeEvent,
}: UseConfirmParams<T>) => {
  const [context, setContext] = useState<T | null>(null);

  const [showConfirm, hideConfirm] = useModal(
    ({ in: open }) => {
      return (
        <ConfirmationDialog
          actions={typeof actions === 'function' ? actions(context) : actions}
          closeEvent={closeEvent}
          icon={icon}
          isDialogOpen={open}
          subtitle={typeof subtitle === 'function' ? subtitle(context) : subtitle}
          title={typeof title === 'function' ? title(context) : title}
          onClose={hideConfirm}
        />
      );
    },
    [context],
  );

  return {
    showConfirm: (context?: T) => {
      setContext(context || null);
      showConfirm();
    },
    hideConfirm: () => {
      hideConfirm();
      setContext(null);
    },
  };
};
